import { createGlobalStyle } from "styled-components"

export const Global = createGlobalStyle`
  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: transparent;

    font-family: -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    
    h1, h2 {
      font-family: -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    #root {
      width: 100%;
      height: 100%;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  *:not(input, textarea) {
    user-select: none;
  }

  input:disabled {
    -webkit-text-fill-color: currentcolor;
    opacity: 1;
  }
`
