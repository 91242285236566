export enum MessageType {
  DeviceCanRegister = "d-can-register",
  DeviceCanConnect = "d-can-connect",
  ServerRegistered = "s-registered",
  ServerScanReceived = "s-scan-received",
  ServerDeleted = "s-deleted",
  ServerIpointUpdated = "s-ipoint-updated",
}

export interface DeviceMessage {
  messageType: MessageType
  params: string[]
}

export const createMessage = (t: MessageType, ...args: string[]) =>
  JSON.stringify({
    messageType: t,
    params: args,
  })
