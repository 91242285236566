import React, { useState, useEffect, useMemo, useContext } from "react"
import ReactDOM from "react-dom"
import {
  GetDeviceDataQuery,
  GetDeviceDataQueryVariables,
  GetDeviceDataQuery_deviceData,
} from "../types/GetDeviceDataQuery"
import useWebsocket, { ReadyState } from "react-use-websocket"
import { BridgeContext, websocketURL } from ".."
import { MessageType, createMessage, DeviceMessage } from "../util/socket"
import styled, { css } from "styled-components"
import { getDefaultEditable, imageURL } from "../util/common"
import Welcome from "./Welcome"
import { Switch, Redirect, useHistory } from "react-router"
import { Route } from "react-router-dom"
import ViewBasket from "./ViewBasket"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Checkout from "./Checkout"
import Modal from "@g51/hubi-components/components/Modal"
import CheckoutSuccess from "./CheckoutSuccess"
import RichTextEditor from "@happens-lol/react-rte"
import Markdown from "@g51/hubi-components/components/Markdown"
import { CustomValues, getThemeProps, useEditable } from "@g51/hubi-components"
import { gql, useApolloClient, useMutation } from "@apollo/client"
import { useEffectOnce } from "react-use"
import { GET_DEVICE_DATA_QUERY } from "../App"
import {
  TriggerEmbedEvent,
  TriggerEmbedEventVariables,
} from "./types/TriggerEmbedEvent"

interface BasketProps {
  deviceData: GetDeviceDataQuery_deviceData
  values: CustomValues
  defaultValues: CustomValues
  clearDeviceProps: () => void
}

export const TRIGGER_EMBED_EVENT_MUTATION = gql`
  mutation TriggerEmbedEvent($deviceId: ID!, $eventType: String!, $data: Map!) {
    triggerEmbedEvent(deviceId: $deviceId, eventType: $eventType, data: $data)
  }
`

const Basket: React.FC<BasketProps> = ({
  deviceData,
  values,
  defaultValues,
  clearDeviceProps,
}) => {
  const history = useHistory()
  const [startSent, setStartSent] = useState(false)
  const websocketOptions = useMemo(
    () => ({
      reconnectAttempts: 500,
      reconnectInterval: 5000,
      shouldReconnect: () => true,
    }),
    []
  )
  const [send, last, readyState] = useWebsocket(websocketURL, websocketOptions)
  const [triggerEmbedEvent] = useMutation<
    TriggerEmbedEvent,
    TriggerEmbedEventVariables
  >(TRIGGER_EMBED_EVENT_MUTATION)

  const [showImprint, setShowImprint] = useState(false)
  const [showDataPrivacy, setShowDataPrivacy] = useState(false)

  const client = useApolloClient()
  const bridge = useContext(BridgeContext)

  useEffectOnce(() => {
    bridge.registerHandler("analytics-event", (data, rescb) => {
      const extraData: { [key: string]: string } = {}
      Object.keys(data)
        .filter((it) => it !== "eventType")
        .forEach((it) => {
          extraData[it] = data[it]
        })

      const variables = {
        deviceId: deviceData.id,
        eventType: data["eventType"] ?? "embed_event",
        data: extraData,
      }

      triggerEmbedEvent({ variables })
      rescb({ status: "success" })
    })
  })

  useEffect(() => {
    if (readyState === ReadyState.OPEN && !startSent) {
      const message = createMessage(MessageType.DeviceCanConnect, deviceData.id)
      send(message)
      setStartSent(true)
    }
  }, [readyState])

  useEffect(() => {
    if (!last) return

    let msg: DeviceMessage | null = null
    try {
      msg = JSON.parse(last.data)
    } catch {
      console.log("failed to parse message")
      return
    }

    msg = msg!!
    switch (msg.messageType) {
      case MessageType.DeviceCanConnect:
        console.log("Device connected!")
        break
      case MessageType.ServerScanReceived:
        const nfcCode = msg.params[1]
        console.log(`Scan received for basket ${nfcCode}`)
        if (history.location.pathname !== `/basket/${nfcCode}`) {
          bridge.callHandler("basket-triggered", { nfc: nfcCode }, () => {})
          history.push(`/basket/${nfcCode}`)
        } else {
          bridge.callHandler("basket-triggered", { nfc: nfcCode }, () => {})
          console.log("Already on correct basket")
        }

        break
      case MessageType.ServerDeleted:
        bridge.callHandler("device-deleted", {}, () => {})
        clearDeviceProps()
        client.resetStore()
        history.push("/")
        break
      case MessageType.ServerIpointUpdated:
        bridge.callHandler("ipoint-updated", {}, () => {})
        ;(async () => {
          try {
            const deviceDataQuery = await client.query<
              GetDeviceDataQuery,
              GetDeviceDataQueryVariables
            >({
              query: GET_DEVICE_DATA_QUERY,
              variables: { deviceId: deviceData.id },
              fetchPolicy: "network-only",
            })

            bridge.callHandler(
              "asset-link-updated",
              { assetLink: deviceDataQuery.data.deviceData.assetLink || "" },
              () => {}
            )
          } catch (err) {}
        })()
        break
      default:
        console.log(`Skipping unknown message type ${msg.messageType}`)
    }
  }, [last])

  const imprint = useMemo(
    () =>
      RichTextEditor.createValueFromString(
        deviceData.instance.strings.imprint,
        "markdown"
      ),
    []
  )

  const dataPrivacyNotice = useMemo(
    () =>
      RichTextEditor.createValueFromString(
        deviceData.instance.strings.dataPrivacyNotice,
        "markdown"
      ),
    []
  )

  const editable = useEditable(getDefaultEditable(values ?? {}))

  const welcomeTheme = useMemo(
    () => getThemeProps(editable, "basket", "welcome"),
    [values]
  )
  const viewBasketTheme = useMemo(
    () => getThemeProps(editable, "basket", "view-basket"),
    [values]
  )
  const checkoutTheme = useMemo(
    () => getThemeProps(editable, "basket", "checkout"),
    [values]
  )
  const checkoutSuccessTheme = useMemo(
    () => getThemeProps(editable, "basket", "checkout-success"),
    [values]
  )

  return (
    <>
      <Modal isShown={showImprint} onHide={() => setShowImprint(false)}>
        <Markdown
          value={imprint}
          primaryColor="#333"
          secondaryColor="#333"
          editable={false}
          align="left"
          onChangeAlign={() => {}}
        />
      </Modal>

      <Modal isShown={showDataPrivacy} onHide={() => setShowDataPrivacy(false)}>
        <Markdown
          value={dataPrivacyNotice}
          primaryColor="#333"
          secondaryColor="#333"
          editable={false}
          align="left"
          onChangeAlign={() => {}}
        />
      </Modal>

      <Page align={welcomeTheme.pageAlign}>
        <Header>
          {["left", "center", "right"].map((pos) => (
            <div className={pos} key={pos}>
              {deviceData?.project.logoAlignment === pos && (
                <img
                  src={imageURL(deviceData!.project.logo)}
                  alt="Logo"
                  className="main"
                />
              )}

              {deviceData?.project.showSubLogo &&
                deviceData?.project.subLogoAlignment === pos && (
                  <img
                    src={imageURL(deviceData!.project.subLogo!!)}
                    alt="Logo"
                    className="sub"
                  />
                )}
            </div>
          ))}
        </Header>

        <Body>
          <TransitionGroup>
            <CSSTransition
              key={history.location.key}
              classNames="route"
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
            >
              <Switch>
                <Route path="/success">
                  <BasketContainer
                    {...checkoutSuccessTheme}
                    sizeBodyNormal={values["font-body"]?.["size-normal"]}
                    sizeBodyBig={values["font-body"]?.["size-big"]}
                    sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
                    sizeHeadingBig={values["font-heading"]?.["size-big"]}
                  >
                    {checkoutSuccessTheme.backgroundImage &&
                      ReactDOM.createPortal(
                        <Background {...checkoutSuccessTheme} />,
                        document.body
                      )}
                    <CheckoutSuccess
                      values={values}
                      defaultValues={defaultValues}
                    />
                  </BasketContainer>
                </Route>

                <Route path="/basket/:nfcCode">
                  <BasketContainer
                    {...viewBasketTheme}
                    sizeBodyNormal={values["font-body"]?.["size-normal"]}
                    sizeBodyBig={values["font-body"]?.["size-big"]}
                    sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
                    sizeHeadingBig={values["font-heading"]?.["size-big"]}
                  >
                    {viewBasketTheme.backgroundImage &&
                      ReactDOM.createPortal(
                        <Background {...viewBasketTheme} />,
                        document.body
                      )}
                    <ViewBasket
                      values={values}
                      defaultValues={defaultValues}
                      projectId={deviceData.project.id}
                      downloadables={deviceData.project.downloadables}
                      deviceId={deviceData.id}
                      defaultDownloadableIcon={
                        deviceData.project.defaultDownloadableIcon ?? undefined
                      }
                    />
                  </BasketContainer>
                </Route>

                <Route path="/checkout/:nfcCode">
                  <BasketContainer
                    {...checkoutTheme}
                    sizeBodyNormal={values["font-body"]?.["size-normal"]}
                    sizeBodyBig={values["font-body"]?.["size-big"]}
                    sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
                    sizeHeadingBig={values["font-heading"]?.["size-big"]}
                  >
                    {checkoutTheme.backgroundImage &&
                      ReactDOM.createPortal(
                        <Background {...checkoutTheme} />,
                        document.body
                      )}
                    <Checkout
                      values={values}
                      defaultValues={defaultValues}
                      projectId={deviceData.project.id}
                      deviceId={deviceData.id ?? ``}
                    />
                  </BasketContainer>
                </Route>

                <Route path="/" exact>
                  <BasketContainer
                    {...welcomeTheme}
                    sizeBodyNormal={values["font-body"]?.["size-normal"]}
                    sizeBodyBig={values["font-body"]?.["size-big"]}
                    sizeHeadingNormal={values["font-heading"]?.["size-normal"]}
                    sizeHeadingBig={values["font-heading"]?.["size-big"]}
                  >
                    {welcomeTheme.backgroundImage &&
                      ReactDOM.createPortal(
                        <Background {...welcomeTheme} />,
                        document.body
                      )}
                    <Welcome values={values} defaultValues={defaultValues} />
                  </BasketContainer>
                </Route>

                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Body>

        <Footer dark={deviceData.instance.strings.dark ?? false}>
          <button onClick={() => setShowImprint(true)}>
            {deviceData.instance.customValues.imprintLink ?? "Impressum"}
          </button>
          <button onClick={() => setShowDataPrivacy(true)}>
            {deviceData.instance.customValues.privacyLink ?? "Datenschutz"}
          </button>
        </Footer>
      </Page>
    </>
  )
}

const Background = styled.div<{ backgroundImage: any; backgroundColor: any }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.backgroundColor};

  ${(props) =>
    props.backgroundImage &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${imageURL(props.backgroundImage)});
    `}
`

export const Page = styled.div<{ align: any }>`
  width: 90%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: transparent;

  ${(props) =>
    props.align === "left" &&
    css`
      align-items: flex-start;
    `}

  ${(props) =>
    props.align === "right" &&
    css`
      align-items: flex-end;
    `}

  .route-enter {
    opacity: 0;
    transform: translateX(-1rem);
  }

  .route-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  .route-exit {
    opacity: 1;
    transform: translateX(0);
  }

  .route-exit-active {
    opacity: 0;
    transform: translateX(1rem);
  }

  .route-enter-active {
    transition: all 250ms ease-in-out;
    transition-property: transform, opacity;
    transition-delay: 150ms;
  }

  .route-exit-active {
    transition: all 250ms linear;
    transition-property: transform, opacity;
  }
`

const Footer = styled.div<{ dark: boolean }>`
  width: 85%;
  height: 8%;

  display: flex;
  justify-content: center;

  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 1.5em 1em;
    height: 1em;
    font-size: 14px;
    color: #555;

    ${({ dark }) =>
      dark &&
      css`
        color: #fff;
      `}

    &:focus {
      outline: none;
    }

    &::-moz-focus-inner {
      border: none;
    }
  }
`

const Body = styled.div`
  width: 85%;
  flex: 1;

  > div {
    width: 100%;
    height: 100%;
  }
`

export const BasketContainer = styled.div<{
  containerBackgroundColor: any
  containerBorderRadius: any
  hasContainer: any
  containerBackgroundImage: any
  hasContainerShadow: any
  sizeBodyNormal?: number
  sizeBodyBig?: number
  sizeHeadingNormal?: number
  sizeHeadingBig?: number
}>`
  width: 100%;
  height: calc(100% - 2rem);

  padding: 1.5rem 3rem;
  margin-top: 2rem;

  border: 1px solid #ddd;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #666;
  border-radius: 4px;
  background-color: ${(props) => props.containerBackgroundColor};
  border-radius: ${(props) => props.containerBorderRadius}px;
  flex-direction: column;

  .DraftEditor-root span {
    font-size: ${(props) => props.sizeBodyNormal || 16}px;
  }

  .DraftEditor-root h3 span {
    font-size: ${(props) => props.sizeBodyBig || 22}px;
  }

  .DraftEditor-root h2 span {
    font-size: ${(props) => props.sizeHeadingNormal || 26}px;
  }

  .DraftEditor-root h1 span {
    font-size: ${(props) => props.sizeHeadingBig || 32}px;
  }

  .category {
    font-size: ${(props) => props.sizeBodyBig || 22}px;
    padding: 18px 16px;
  }

  .downloadables {
    .info {
      padding-right: 16px;
    }

    .item .meta {
      font-size: ${(props) => props.sizeBodyNormal || 16}px;

      .desc {
        font-size: 0.95em;
      }
    }

    .info + div {
      flex-shrink: 0;
    }
  }

  ${(props) =>
    props.hasContainerShadow &&
    css`
      box-shadow: 0px 0px 30px 16px rgba(0, 0, 0, 0.1);
    `}

  ${(props) =>
    props.containerBackgroundImage &&
    css`
      background-position: center center;
      background-size: cover;
      background-image: url(${imageURL(props.containerBackgroundImage)});
    `}

    ${(props) =>
    !props.hasContainer &&
    css`
      border-color: transparent !important;
      background: transparent !important;
      box-shadow: none !important;
      padding: 1.5rem 0;
      height: 100% !important;
      margin-top: 0 !important;
    `}
`

export const Header = styled.div`
  width: 85%;
  margin-top: 40px;

  display: flex;
  justify-content: space-between;

  .right,
  .left,
  .center {
    flex: 1;
    display: flex;
  }

  .right {
    justify-content: flex-end;
  }

  .center {
    justify-content: center;
  }

  .right .sub:not(:first-child) {
    margin-left: 2rem;
  }

  .left .main:not(:last-child) {
    margin-right: 2rem;
  }

  .center .main:not(:last-child) {
    margin-right: 2rem;
  }

  img {
    object-fit: contain;
    max-height: 400px;
    max-width: 300px;
  }
`

export default Basket
