import { CustomValues, DefaultBaseConfig } from "@g51/hubi-components"
import React from "react"
import styled, { keyframes } from "styled-components"
import { CMS_CONFIG } from ".."

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getDefaultEditable = (values: CustomValues) => ({
  baseConfig: DefaultBaseConfig,
  editable: false,
  outer: values,
  setOuter: () => {},
  elements: {},
})

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const isValidEmail = (input: string) =>
  (input.match(EMAIL_REGEX) ?? []).length > 0

export const imageURL = (file: string) => `${CMS_CONFIG.cmsUrl}/images/${file}`

const loadingAnim = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  }

  40% { 
    transform: scale(1.0);
  }
`

const LoadingContainer = styled.div<{ color?: string }>`
  margin: 0 auto 0;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  > div {
    width: 25px;
    height: 25px;
    background-color: ${(props) => props.color ?? "#888"};

    border-radius: 100%;
    display: inline-block;
    animation: ${loadingAnim} 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`

export const Loading = () => (
  <LoadingContainer>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </LoadingContainer>
)
