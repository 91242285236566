import React from "react"
import WelcomeTemplate from "@g51/hubi-components/templates/basket/Welcome"
import { CustomValues } from "@g51/hubi-components"

const Welcome: React.FC<{
  values: CustomValues
  defaultValues: CustomValues
}> = ({ values, defaultValues }) => {
  return <WelcomeTemplate value={values} baseConfig={defaultValues} />
}

export default Welcome
