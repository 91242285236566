import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import {
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  ApolloProvider,
  ApolloClient,
} from "@apollo/client"
import App from "./App"

export let CMS_CONFIG = {
  hostnames: ["localhost"],
  cmsUrl: process.env.REACT_APP_CMS_URL as string,
  socketUrl: process.env.REACT_APP_CMS_SOCKET_URL as string,
  deviceSecret: process.env.REACT_APP_DEVICE_SECRET as string,
  name: "Staging",
}

const INSTANCE_CMS_CONFIG = {
  staging: {
    hostnames: ["localhost", "basket.staging.g51.dev"],
    cmsUrl: process.env.REACT_APP_CMS_URL as string,
    socketUrl: process.env.REACT_APP_CMS_SOCKET_URL as string,
    deviceSecret: process.env.REACT_APP_DEVICE_SECRET as string,
    name: "Staging",
  },
  expopartner: {
    hostnames: ["basket.expopartner.g51.dev"],
    cmsUrl: "https://cms.expopartner.g51.dev",
    socketUrl: "wss://cms.expopartner.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_EXPOPARTNER_DEVICE_SECRET as string,
    name: "Expopartner",
  },
  expopartner2: {
    hostnames: ["basket.expopartner2.g51.dev"],
    cmsUrl: "https://cms.expopartner2.g51.dev",
    socketUrl: "wss://cms.expopartner2.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_EXPOPARTNER2_DEVICE_SECRET as string,
    name: "Expopartner 2",
  },
  pfizer: {
    hostnames: ["basket.pfizer.g51.dev"],
    cmsUrl: "https://cms.pfizer.g51.dev",
    socketUrl: "wss://cms.pfizer.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_PFIZER_DEVICE_SECRET as string,
    name: "Pfizer",
  },
  merck: {
    hostnames: ["basket.merck.g51.dev"],
    cmsUrl: "https://cms.merck.g51.dev",
    socketUrl: "wss://cms.merck.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_MERCK_DEVICE_SECRET as string,
    name: "Merck",
  },
  sanofi: {
    hostnames: ["basket.sanofi.g51.dev"],
    cmsUrl: "https://cms.sanofi.g51.dev",
    socketUrl: "wss://cms.sanofi.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_SANOFI_DEVICE_SECRET as string,
    name: "Sanofi",
  },
  alexion: {
    hostnames: ["basket.alexion.g51.dev"],
    cmsUrl: "https://cms.alexion.g51.dev",
    socketUrl: "wss://cms.alexion.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_ALEXION_DEVICE_SECRET as string,
    name: "Alexion",
  },
  incyte: {
    hostnames: ["basket.incyte.g51.dev"],
    cmsUrl: "https://cms.incyte.g51.dev",
    socketUrl: "wss://cms.incyte.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_INCYTE_DEVICE_SECRET as string,
    name: "Incyte",
  },
  takeda: {
    hostnames: ["basket.takeda.g51.dev"],
    cmsUrl: "https://cms.takeda.g51.dev",
    socketUrl: "wss://cms.takeda.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_TAKEDA_DEVICE_SECRET as string,
    name: "Takeda",
  },
  janssen: {
    hostnames: ["basket.janssen.g51.dev"],
    cmsUrl: "https://cms.janssen.g51.dev",
    socketUrl: "wss://cms.janssen.g51.dev/devices/socket",
    deviceSecret: process.env.REACT_APP_JANSSEN_DEVICE_SECRET as string,
    name: "Janssen",
  },
}

for (let it of Object.values(INSTANCE_CMS_CONFIG)) {
  const matches = it.hostnames.filter((host) =>
    window.location.hostname.startsWith(host)
  )

  if (matches.length > 0) {
    console.log(
      `selected ${it.name} (${it.cmsUrl}) for hostname ${window.location.hostname}`
    )
    CMS_CONFIG = it
    break
  }
}

interface WebViewBridge {
  callHandler: (ev: string, data: any, cb: () => void) => void
  registerHandler: (
    ev: string,
    cb: (data: any, rescb: (data: any) => void) => void
  ) => void
}

export const BridgeContext = React.createContext<WebViewBridge>({
  callHandler: (ev, data) => {
    console.log(`handler ${ev} called`)
    console.dir(data)
  },
  registerHandler: (ev) => console.log(`handler ${ev} registered`),
})

function setupWebViewJavascriptBridge(
  callback: (bridge: WebViewBridge) => void
) {
  const w = window as any

  if (w.WebViewJavascriptBridge) return callback(w.WebViewJavascriptBridge)
  if (w.WVJBCallbacks) return w.WVJBCallbacks.push(callback)

  w.WVJBCallbacks = [callback]
  const WVJBIframe = document.createElement("iframe")
  WVJBIframe.style.display = "none"
  WVJBIframe.src = "https://__bridge_loaded__"
  document.documentElement.appendChild(WVJBIframe)

  setTimeout(() => document.documentElement.removeChild(WVJBIframe), 0)
}

export const secret = CMS_CONFIG.deviceSecret
export const cmsURL = `${CMS_CONFIG.cmsUrl}/query`
export const websocketURL = `${CMS_CONFIG.socketUrl}?secret=${secret}`

const httpLink = new HttpLink({ uri: cmsURL })

const authLink = new ApolloLink((op, forward) => {
  op.setContext(() => ({ headers: { "X-Device-Secret": secret } }))
  return forward(op)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, httpLink),
})

const cb = (bridge: any) => {
  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <BridgeContext.Provider value={bridge}>
          <App />
        </BridgeContext.Provider>
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById("root")
  )
}

const params = new URLSearchParams(window.location.search)
if (params.has("embed")) setupWebViewJavascriptBridge(cb)
else
  cb({
    callHandler: () => {},
    registerHandler: () => {},
  })
