import React, { useContext, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router"
import { gql, useQuery, useMutation } from "@apollo/client"
import { GetDeviceDataQuery_deviceData_project_downloadables } from "../types/GetDeviceDataQuery"
import { GetBasketQuery, GetBasketQueryVariables } from "./types/GetBasketQuery"
import {
  AddBasketItemMutation,
  AddBasketItemMutationVariables,
} from "./types/AddBasketItemMutation"
import {
  RemoveBasketItemMutation,
  RemoveBasketItemMutationVariables,
} from "./types/RemoveBasketItemMutation"
import {
  AddAllBasketItemsMutation,
  AddAllBasketItemsMutationVariables,
} from "./types/AddAllBasketItemsMutation"
import ViewBasketTemplate from "@g51/hubi-components/templates/basket/ViewBasket"
import { Category, CustomValues } from "@g51/hubi-components"
import { imageURL } from "../util/common"
import { BridgeContext } from ".."
import {
  TriggerEmbedEvent,
  TriggerEmbedEventVariables,
} from "./types/TriggerEmbedEvent"
import { TRIGGER_EMBED_EVENT_MUTATION } from "./Basket"

export const BasketFragments = {
  BasketFields: gql`
    fragment BasketFields on Basket {
      id
      nfcCode
      downloadables {
        id
        name
        categoryId
        description
        previewImage
      }
    }
  `,
}

export const GET_BASKET_QUERY = gql`
  query GetBasketQuery($projectId: ID!, $nfcCode: String!) {
    basket(projectId: $projectId, nfcCode: $nfcCode) {
      ...BasketFields
    }
  }
  ${BasketFragments.BasketFields}
`

const ADD_BASKET_ITEM_MUTATION = gql`
  mutation AddBasketItemMutation($basketId: ID!, $itemId: ID!) {
    addBasketItem(basketId: $basketId, itemId: $itemId) {
      ...BasketFields
    }
  }
  ${BasketFragments.BasketFields}
`

const REMOVE_BASKET_ITEM_MUTATION = gql`
  mutation RemoveBasketItemMutation($basketId: ID!, $itemId: ID!) {
    removeBasketItem(basketId: $basketId, itemId: $itemId) {
      ...BasketFields
    }
  }
  ${BasketFragments.BasketFields}
`

const ADD_ALL_BASKET_ITEMS_MUTATION = gql`
  mutation AddAllBasketItemsMutation($basketId: ID!, $projectId: ID!) {
    addAllBasketItems(projectId: $projectId, basketId: $basketId) {
      ...BasketFields
    }
  }
  ${BasketFragments.BasketFields}
`

interface ViewBasketProps {
  projectId: string
  downloadables: GetDeviceDataQuery_deviceData_project_downloadables
  values: CustomValues
  defaultValues: CustomValues
  defaultDownloadableIcon?: string
  deviceId: string
}

interface Params {
  nfcCode: string
}

const ViewBasket: React.FC<ViewBasketProps> = ({
  projectId,
  downloadables,
  values,
  defaultValues,
  defaultDownloadableIcon,
  deviceId,
}) => {
  const history = useHistory()
  const { nfcCode } = useParams<Params>()
  const [loadingBasketItemId, setLoadingBasketItemId] = useState<string | null>(
    null
  )

  const getBasketQuery = useQuery<GetBasketQuery, GetBasketQueryVariables>(
    GET_BASKET_QUERY,
    {
      variables: { projectId, nfcCode },
      onError: (_) => {
        if (nfcCode !== "demo") {
          bridge.callHandler("get-basket-error", {}, () => {})
          history.push("/")
        }
      },
      onCompleted: (data) => {
        bridge.callHandler("basket-updated", data.basket, () => {})
      },
    }
  )

  const [addBasketItem, addBasketItemMutation] = useMutation<
    AddBasketItemMutation,
    AddBasketItemMutationVariables
  >(ADD_BASKET_ITEM_MUTATION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      bridge.callHandler("basket-updated", data.addBasketItem, () => {})
      setLoadingBasketItemId(null)
    },
    update: (store, { data }) => {
      if (!data) return
      store.writeQuery<GetBasketQuery, GetBasketQueryVariables>({
        query: GET_BASKET_QUERY,
        variables: { nfcCode, projectId },
        data: { basket: data.addBasketItem },
      })
    },
  })

  const [removeBasketItem, removeBasketItemMutation] = useMutation<
    RemoveBasketItemMutation,
    RemoveBasketItemMutationVariables
  >(REMOVE_BASKET_ITEM_MUTATION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      bridge.callHandler("basket-updated", data.removeBasketItem, () => {})
      setLoadingBasketItemId(null)
    },
    update: (store, { data }) => {
      if (!data) return
      store.writeQuery<GetBasketQuery, GetBasketQueryVariables>({
        query: GET_BASKET_QUERY,
        variables: { nfcCode, projectId },
        data: { basket: data.removeBasketItem },
      })
    },
  })

  const [addAllBasketItems, addAllBasketItemsMutation] = useMutation<
    AddAllBasketItemsMutation,
    AddAllBasketItemsMutationVariables
  >(ADD_ALL_BASKET_ITEMS_MUTATION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      bridge.callHandler("basket-updated", data.addAllBasketItems, () => {})
    },
    update: (store, { data }) => {
      if (!data) return
      store.writeQuery<GetBasketQuery, GetBasketQueryVariables>({
        query: GET_BASKET_QUERY,
        variables: { nfcCode, projectId },
        data: { basket: data.addAllBasketItems },
      })
    },
  })

  const [triggerEmbedEvent] = useMutation<
    TriggerEmbedEvent,
    TriggerEmbedEventVariables
  >(TRIGGER_EMBED_EVENT_MUTATION)

  const bridge = useContext(BridgeContext)
  useEffect(() => {
    if (!getBasketQuery?.data?.basket?.id) {
      bridge.callHandler("waiting-for-basket", {}, () => {})
      return
    }

    bridge.registerHandler("add-basket-item", (data, cb) => {
      if (!getBasketQuery?.data?.basket?.id) {
        cb({ status: "no-basket" })
        return
      }

      addBasketItem({
        variables: {
          itemId: data.id,
          basketId: getBasketQuery?.data?.basket?.id,
        },
      })

      cb({ status: "success" })
    })

    bridge.registerHandler("remove-basket-item", (data, cb) => {
      if (!getBasketQuery?.data?.basket?.id) {
        cb({ status: "no-basket" })
        return
      }

      removeBasketItem({
        variables: {
          itemId: data.id,
          basketId: getBasketQuery?.data?.basket?.id,
        },
      })

      cb({ status: "success" })
    })

    bridge.registerHandler("add-all-basket-items", (_data, cb) => {
      if (!getBasketQuery?.data?.basket?.id) {
        cb({ status: "no-basket" })
        return
      }

      addAllBasketItems({
        variables: {
          projectId,
          basketId: getBasketQuery?.data?.basket?.id,
        },
      })

      cb({ status: "success" })
    })

    bridge.callHandler("basket-handlers-registered", {}, () => {})
  }, [getBasketQuery.data])

  const mappedSelectedIds =
    getBasketQuery.data?.basket?.downloadables?.reduce<{
      [id: string]: boolean
    }>((acc, it) => {
      acc[it.id] = true
      return acc
    }, {}) ?? {}

  useEffect(() => {
    bridge.callHandler(
      "basket-updated",
      getBasketQuery.data?.basket?.downloadables,
      () => {}
    )
  }, [getBasketQuery.data])

  const categories = downloadables.categories.map<Category>((it) => ({
    id: it.category.id,
    name: it.category.name,
    downloadables: it.downloadables.map(
      ({ id, name, description, previewImage }) => ({
        id,
        name,
        selected: !!mappedSelectedIds[id],
        description,
        previewImage,
      })
    ),
  }))

  return (
    <ViewBasketTemplate
      value={values}
      baseConfig={defaultValues}
      categories={categories}
      onCheckout={() => {
        triggerEmbedEvent({
          variables: {
            deviceId,
            eventType: "basket-checkout-started",
            data: {
              basketCode: nfcCode,
              category: "basket",
            },
          },
        })

        history.push(`/checkout/${nfcCode}`)
      }}
      fallbackIcon={defaultDownloadableIcon}
      loading={
        addBasketItemMutation.loading ||
        removeBasketItemMutation.loading ||
        addAllBasketItemsMutation.loading
      }
      makeImageURL={imageURL}
      loadingAll={addAllBasketItemsMutation.loading}
      loadingItemId={loadingBasketItemId ?? undefined}
      addBasketItem={(id) => {
        if (!getBasketQuery?.data?.basket?.id) return
        setLoadingBasketItemId(id)
        addBasketItem({
          variables: {
            itemId: id,
            basketId: getBasketQuery?.data?.basket?.id,
          },
        })
      }}
      removeBasketItem={(id) => {
        if (!getBasketQuery?.data?.basket?.id) return
        setLoadingBasketItemId(id)
        removeBasketItem({
          variables: {
            itemId: id,
            basketId: getBasketQuery.data?.basket?.id,
          },
        })
      }}
      addAllBasketItems={() => {
        addAllBasketItems({
          variables: {
            projectId,
            basketId: getBasketQuery.data?.basket?.id ?? "",
          },
        })
      }}
    />
  )
}

export default ViewBasket
