import React, { useState } from "react"
import { isValidEmail, timeout } from "../util/common"
import { useParams, useHistory } from "react-router"
import { GET_BASKET_QUERY } from "./ViewBasket"
import { GetBasketQueryVariables, GetBasketQuery } from "./types/GetBasketQuery"
import { useQuery, useMutation, gql } from "@apollo/client"
import {
  CheckoutBasketMutation,
  CheckoutBasketMutationVariables,
} from "./types/CheckoutBasketMutation"
import CheckoutTemplate from "@g51/hubi-components/templates/basket/Checkout"
import { CustomValues } from "@g51/hubi-components"

interface CheckoutProps {
  deviceId: string
  projectId: string
  values: CustomValues
  defaultValues: CustomValues
}

interface Params {
  nfcCode: string
}

const BASKET_CHECKOUT_MUTATION = gql`
  mutation CheckoutBasketMutation(
    $basketId: ID!
    $email: String!
    $deviceId: ID!
  ) {
    basketCheckout(basketId: $basketId, email: $email, deviceId: $deviceId)
  }
`

const Checkout: React.FC<CheckoutProps> = ({
  deviceId,
  projectId,
  values,
  defaultValues,
}) => {
  const history = useHistory()
  const { nfcCode } = useParams<Params>()
  const [email, setEmail] = useState("")
  const [touched, setTouched] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const getBasketQuery = useQuery<GetBasketQuery, GetBasketQueryVariables>(
    GET_BASKET_QUERY,
    {
      variables: { projectId, nfcCode },
      onCompleted: () => {},
      onError: (err) => console.log(`error checkint out: ${err}`),
    }
  )

  const [checkoutBasket, basketCheckoutMutation] = useMutation<
    CheckoutBasketMutation,
    CheckoutBasketMutationVariables
  >(BASKET_CHECKOUT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setTouched(false)
      setEmail("")
      setSubmitted(false)
      history.push("/success")
    },
    onError: (err) => {
      setSubmitted(false)
      console.log(`error checking out: ${err}`)
    },
  })

  return (
    <CheckoutTemplate
      value={values}
      baseConfig={defaultValues}
      touched={touched}
      email={email}
      emailPlaceholder={values.emailTexts?.emailPlaceholder}
      emailInvalid={values.emailTexts?.emailInvalid}
      setEmail={(value) => {
        setEmail(value)
        setEmailValid(isValidEmail(value))
      }}
      emailValid={emailValid}
      loading={submitted || basketCheckoutMutation.loading}
      onSend={async () => {
        if (!touched) setTouched(true)
        if (!isValidEmail(email ?? "")) return
        setSubmitted(true)

        if (nfcCode === "demo") {
          await timeout(500)

          setTouched(false)
          setEmail("")
          setSubmitted(false)
          history.push("/success")
          return
        }

        checkoutBasket({
          variables: {
            email,
            basketId: getBasketQuery.data?.basket?.id ?? "",
            deviceId,
          },
        })
      }}
    />
  )
}

export default Checkout
