import React, { useContext, useMemo } from "react"
import { useLocalStorage } from "react-use"
import RegisterPrompt from "./RegisterPrompt"
import { Global } from "./styles"
import styled, { css } from "styled-components"
import { gql, useQuery } from "@apollo/client"
import Basket from "./basket/Basket"
import { Loading } from "./util/common"
import {
  GetDeviceDataQueryVariables,
  GetDeviceDataQuery,
} from "./types/GetDeviceDataQuery"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { Normalize } from "styled-normalize"
import {
  deserializeCustomValues,
  DefaultBaseConfig,
} from "@g51/hubi-components"
import { Helmet } from "react-helmet"
import { BridgeContext, CMS_CONFIG } from "."

export const GET_DEVICE_DATA_QUERY = gql`
  query GetDeviceDataQuery($deviceId: ID!) {
    deviceData(deviceId: $deviceId) {
      id
      name
      assetLink
      iPointId
      project {
        id
        name
        slug
        logo
        subLogo
        showSubLogo
        logoAlignment
        subLogoAlignment
        defaultDownloadableIcon
        downloadables {
          categories {
            category {
              id
              name
            }
            downloadables {
              id
              name
              description
              previewImage
            }
          }
        }
      }
      instance {
        clientName
        logo
        strings {
          imprint
          dataPrivacyNotice
          dark
        }
        customValues
      }
      customValues
    }
  }
`

export interface DeviceProps {
  deviceId: string
  projectId: string
}

const App = () => {
  const [deviceProps, setDeviceProps] = useLocalStorage<DeviceProps | null>(
    "device-props"
  )

  const bridge = useContext(BridgeContext)
  const deviceDataQuery = useQuery<
    GetDeviceDataQuery,
    GetDeviceDataQueryVariables
  >(GET_DEVICE_DATA_QUERY, {
    variables: { deviceId: deviceProps?.deviceId ?? "" },
    skip: !deviceProps?.deviceId,
    onCompleted: (data) => {
      bridge.callHandler(
        "asset-link-updated",
        { assetLink: data.deviceData.assetLink || "" },
        () => {}
      )

      bridge.callHandler(
        "device-data-received",
        {
          deviceId: data.deviceData.id,
          deviceName: data.deviceData.name,
          projectId: data.deviceData.project.id,
          projectName: data.deviceData.project.name,
          downloadables: data.deviceData.project.downloadables,
          assetLink: data.deviceData.assetLink,
          imprint: data.deviceData.instance.strings.imprint,
          dataPrivacyNotice: data.deviceData.instance.strings.dataPrivacyNotice,
          iPointId: data.deviceData.iPointId,
        },
        () => console.log("device data sent!")
      )
    },
  })

  const values = useMemo(
    () =>
      !!deviceDataQuery.data
        ? deserializeCustomValues(deviceDataQuery.data.deviceData.customValues)
        : null,
    [deviceDataQuery]
  )

  return (
    <>
      <Normalize />
      <Global />

      <Helmet>
        <title>{`${CMS_CONFIG.name} HUB-i Basket`}</title>

        {!!values?.["font-heading"] && (
          <link rel="stylesheet" href={values["font-heading"]?.["css-url"]} />
        )}

        {!!values?.["font-body"] && (
          <link rel="stylesheet" href={values["font-body"]?.["css-url"]} />
        )}
      </Helmet>

      <Container
        headingFontFamily={`${values?.["font-heading"]?.family ?? ""}`}
        bodyFontFamily={`${values?.["font-body"]?.family ?? ""}`}
      >
        <SwitchTransition>
          <CSSTransition
            key={`${deviceDataQuery.loading}`}
            classNames="fade"
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
          >
            <div className="transition-container">
              {deviceDataQuery.loading && <Loading />}

              {!deviceDataQuery.loading && (
                <>
                  {(!deviceProps || !deviceDataQuery.data) && (
                    <RegisterPrompt setDeviceProps={setDeviceProps} />
                  )}

                  {!!deviceProps && !!deviceDataQuery.data && values && (
                    <Basket
                      clearDeviceProps={() => setDeviceProps(null)}
                      values={values}
                      defaultValues={DefaultBaseConfig}
                      deviceData={deviceDataQuery.data.deviceData}
                    />
                  )}
                </>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Container>
    </>
  )
}

const Container = styled.div<{
  headingFontFamily: string | null
  bodyFontFamily: string | null
}>`
  ${(props) =>
    props.bodyFontFamily &&
    css`
      * {
        font-family: ${props.bodyFontFamily || "sans-serif"};
      }
    `}

  ${(props) =>
    props.headingFontFamily &&
    css`
      h1,
      h2 {
        * {
          font-family: ${props.headingFontFamily || "sans-serif"};
        }
      }
    `}

  width: 100%;
  height: 100%;

  > .transition-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 500ms;
  }
`

export default App
