import React, { useContext } from "react"
import CheckoutSuccessTemplate from "@g51/hubi-components/templates/basket/CheckoutSuccess"
import { CustomValues } from "@g51/hubi-components"
import { useEffectOnce, useTimeout } from "react-use"
import { Redirect } from "react-router"
import { BridgeContext } from ".."

const CheckoutSuccess: React.FC<{
  values: CustomValues
  defaultValues: CustomValues
}> = ({ values, defaultValues }) => {
  const [isReady] = useTimeout(10000)

  const bridge = useContext(BridgeContext)
  useEffectOnce(() => {
    bridge.callHandler("checkout-done", {}, () => {})
  })

  return isReady() ? (
    <Redirect to="/" />
  ) : (
    <CheckoutSuccessTemplate
      value={values}
      baseConfig={defaultValues}
      editable={false}
    />
  )
}

export default CheckoutSuccess
