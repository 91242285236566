import React, { useEffect, useMemo, useState } from "react"
import useWebsocket, { ReadyState } from "react-use-websocket"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { createMessage, MessageType, DeviceMessage } from "./util/socket"
import styled from "styled-components"
import { Loading } from "./util/common"
import QRCode from "qrcode.react"
import { CMS_CONFIG, websocketURL } from "."

interface RegisterPromptProps {
  setDeviceProps: (props: { deviceId: string; projectId: string }) => void
}

const RegisterPrompt: React.FC<RegisterPromptProps> = ({ setDeviceProps }) => {
  const [startSent, setStartSent] = useState(false)
  const websocketOptions = useMemo(
    () => ({
      reconnectAttempts: 500,
      reconnectInterval: 5000,
      shouldReconnect: () => true,
    }),
    []
  )

  const [send, last, readyState, getSocket] = useWebsocket(
    websocketURL,
    websocketOptions
  )
  const [regId, setRegId] = useState("")

  const [state, setState] = useState<"loading" | "code" | "registered">(
    "loading"
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN && !startSent) {
      const message = createMessage(MessageType.DeviceCanRegister)
      send(message)
      setStartSent(true)
    }
  }, [readyState])

  useEffect(() => {
    if (!last) return

    let msg: DeviceMessage | null = null
    try {
      msg = JSON.parse(last.data)
    } catch {
      console.log("failed to parse message")
      return
    }

    msg = msg!!
    switch (msg.messageType) {
      case MessageType.DeviceCanRegister:
        setRegId(msg.params[0])
        setState("code")
        break
      case MessageType.ServerRegistered:
        getSocket().close()
        setDeviceProps({ deviceId: regId, projectId: msg.params[0] })
        break
      default:
        console.log(`Skipping unknown message type ${msg.messageType}`)
    }
  }, [last])

  return (
    <Container>
      <SwitchTransition>
        <CSSTransition
          key={state}
          classNames="fade"
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
        >
          <div>
            {state === "loading" && <Loading />}

            {state === "code" && (
              <QRCodeContainer>
                <div className="instance-name">
                  <div>{CMS_CONFIG.name} HUB-i Basket</div>
                </div>

                <QRCode
                  fgColor="#444"
                  value={regId}
                  renderAs="svg"
                  style={{ width: 400, height: 400 }}
                />

                <div className="hubi-logo-container">
                  <div className="hubi-logo">
                    <div>HUB-i</div>
                    <div>BSKT</div>
                  </div>
                </div>
              </QRCodeContainer>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  )
}

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  > * {
    flex: 1;
  }

  .instance-name {
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .hubi-logo-container {
    display: flex;
    align-items: flex-end;
    padding-bottom: 32px;

    .hubi-logo {
      width: 6.2rem;
      height: 6rem;
      border: 6px solid black;
      font-size: 1.6rem;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: bold;
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 500ms;
  }
`

export default RegisterPrompt
